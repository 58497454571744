import React from 'react';
import Register from '../components/register';


const RegisterN = () => {
    return (
   <Register/>
)
}

export default RegisterN;