import React from 'react';
import Contact from '../components/Gallery';
import Footer from '../components/footer';
import Navbar from '../components/navbar';
import Gallery from '../components/Gallery';


const Gallerys = () => {
    return (
        <>
        <Navbar/>
        <Gallery/>
      <Footer/>
   </>
  
)
}

export default Gallerys;