import React from 'react';
import Contact from '../components/contact';
import Footer from '../components/footer';
import Navbar from '../components/navbar';


const Contactus = () => {
    return (
        <>
        <Navbar/>
         <Contact/>
   <Footer/>
   </>
  
)
}

export default Contactus;